import { formattedAuctionHouses, formattedFlags, formattedPriorities, formattedStatuses } from 'cleaning-lots/constants/raw-auction';
import {
  getAuction,
  loadInitialData,
  setAuctionDateFrom,
  setAuctionDateTo,
  setAuctionFlag,
  setAuctionHouse,
  setAuctionStatus,
  setCreatedDateFrom,
  setCreatedDateTo,
  setFilters,
  setPage,
  setPublishedDateFrom,
  setPublishedDateTo,
} from 'cleaning-lots/actions/admin-cleaning';

import { Form } from 'antd';
import { Search } from 'cleaning-lots/components/AdminCleaningUI/Search';
import { compose } from 'recompose';
import { connect } from 'react-redux';
import { denormalizeRows } from '../normalization/denormalize';
import { formatDate } from 'shared/helpers/format';
import { getNotNullFields } from 'shared/tools/form';
import history from 'shared/tools/history';
import queryString from 'query-string';

const formatCleaners = cleaners =>
  cleaners.map(cleaner => ({
    key: cleaner.id,
    value: cleaner.id,
    label: `${cleaner.name} ${cleaner.surname}`,
  }));

const stateToProps = state => ({
  auctionStatuses: formattedStatuses,
  auctionFlags: formattedFlags,
  auctionHouses: formattedAuctionHouses,
  priorities: formattedPriorities,
  cleaners: formatCleaners(denormalizeRows(state.cleaners)),
  isFieldsDisabled: state.auctions.isLoading,
  isAdmin: state.user.userInfo.role.name === 'admin',

  auctionHouseId: state.auctions.auctionHouseId,
  createdDateFrom: state.auctions.createdDateFrom,
  createdDateTo: state.auctions.createdDateTo,
  publishedDateFrom: state.auctions.publishedDateFrom,
  publishedDateTo: state.auctions.publishedDateTo,
  auctionStatus: state.auctions.auctionStatus,
  auctionFlag: state.auctions.auctionFlag,
  auctionDateFrom: state.auctions.auctionDateFrom,
  auctionDateTo: state.auctions.auctionDateTo,
});

const dispatchToProps = dispatch => ({
  findAuctions: fields => {
    const filledFields = getNotNullFields({
      ...fields,
      start_date: fields && formatDate(fields.start_date),
      end_date: fields && formatDate(fields.end_date),
    });

    dispatch(setPage(1));
    dispatch(setFilters(filledFields));
    dispatch(getAuction());
  },
  setFilterLink: params => {
    const { auctionHouseId, createdDateFrom, createdDateTo, publishedDateFrom, publishedDateTo, auctionStatus, auctionFlag, auctionDateFrom, auctionDateTo } = params;
    history.push({
      search: queryString.stringify({
        auctionHouseId,
        createdDateFrom,
        createdDateTo,
        publishedDateFrom,
        publishedDateTo,
        auctionStatus,
        auctionFlag,
        auctionDateFrom,
        auctionDateTo,
      }),
      pathname: '/cleaning-lots/admin-cleaning-ui',
    });
  },
  setAuctionHouse: auctionHouseId => {
    dispatch(setAuctionHouse(auctionHouseId));
  },
  setCreatedDateFrom: createdDateFrom => {
    dispatch(setCreatedDateFrom(createdDateFrom));
  },
  setCreatedDateTo: createdDateTo => {
    dispatch(setCreatedDateTo(createdDateTo));
  },
  setPublishedDateFrom: publishedDateFrom => {
    dispatch(setPublishedDateFrom(publishedDateFrom));
  },
  setPublishedDateTo: publishedDateTo => {
    dispatch(setPublishedDateTo(publishedDateTo));
  },
  setAuctionStatus: auctionStatus => {
    dispatch(setAuctionStatus(auctionStatus));
  },
  setAuctionFlag: auctionFlag => {
    dispatch(setAuctionFlag(auctionFlag));
  },
  setAuctionDateFrom: auctionDateFrom => {
    dispatch(setAuctionDateFrom(auctionDateFrom));
  },
  setAuctionDateTo: auctionDateTo => {
    dispatch(setAuctionDateTo(auctionDateTo));
  },
  loadInitialData: () => dispatch(loadInitialData()),
});

export default compose(connect(stateToProps, dispatchToProps), Form.create())(Search);
