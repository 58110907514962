function capitalize(str, lower = false) {
  return (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, match => match.toUpperCase());
}

const nationalities = [
  'afghan',
  'albanian',
  'algerian',
  'american samoan',
  'andorran',
  'angolan',
  'anguillan',
  'antiguan/barbudan',
  'argentine',
  'armenian',
  'aruban',
  'australian',
  'austrian',
  'azerbaijani',
  'bahamian',
  'bahraini',
  'bangladeshi',
  'barbadian',
  'belarusian',
  'belgian',
  'beninese',
  'bermudian',
  'bhutanese',
  'bolivian',
  'bosnian',
  'herzegovinian',
  'motswana',
  'brazilian',
  'british virgin islander',
  'bruneian',
  'bulgarian',
  'burkinabe',
  'burmese',
  'burundian',
  'cambodian',
  'canadian',
  'canadian',
  'cape verdean',
  'caymanian',
  'central african',
  'chadian',
  'chilean',
  'chinese',
  'christmas island',
  'cocos islander',
  'colombian',
  'comoran',
  'congo',
  'cook islander',
  'costa rican',
  'ivoirian',
  'croatian',
  'cuban',
  'cypriot',
  'czech',
  'danish',
  'djiboutian',
  'dominican',
  'east timorese',
  'ecuadorian',
  'egyptian',
  'salvadoran',
  'equatorial guinean',
  'eritrean',
  'estonian',
  'ethiopian',
  'falkland island',
  'faroese',
  'fijian',
  'finnish',
  'french',
  'french guianese',
  'french polynesian',
  'gabonese',
  'gambian',
  'georgian',
  'german',
  'ghanaian',
  'gibraltar',
  'greek',
  'greenlander',
  'grenadian',
  'guadeloupe',
  'guamanian',
  'guatemalan',
  'channel islander',
  'guinean',
  'haitian',
  'honduran',
  'hong kong',
  'hungarian',
  'icelandic',
  'indian',
  'indonesian',
  'iranian',
  'iraqi',
  'irish',
  'manx',
  'israeli',
  'italian',
  'jamaican',
  'japanese',
  'channel islander',
  'jordanian',
  'kazakhstani',
  'kenyan',
  'i-kiribati',
  'north korean',
  'south korean',
  'kuwaiti',
  'kyrgyzstani',
  'laotian',
  'latvian',
  'lebanese',
  'basotho',
  'liberian',
  'libyan',
  'lithuanian',
  'luxembourger',
  'macedonian',
  'malagasy',
  'malawian',
  'malaysian',
  'maldivian',
  'malian',
  'maltese',
  'marshallese',
  'martiniquais',
  'mauritanian',
  'mahoran',
  'mexican',
  'micronesia',
  'moldovan',
  'monaco',
  'mongolian',
  'montenegrin',
  'montserratian',
  'moroccan',
  'mozambican',
  'namibian',
  'nauruan',
  'nepalese',
  'dutch',
  'dutch antillean',
  'new caledonian',
  'new zealander',
  'nicaraguan',
  'nigerien',
  'nigerian',
  'niuean',
  'norfolk islander',
  'norwegian',
  'omani',
  'pakistani',
  'palauan',
  'panamanian',
  'papua new guinean',
  'paraguayan',
  'peruvian',
  'filipino',
  'pitcairn islander',
  'polish',
  'portuguese',
  'puerto rican',
  'qatari',
  'reunionese',
  'romanian',
  'russian',
  'rwandan',
  'saint helenian',
  'saint vincentian',
  'vincentian',
  'samoan',
  'sammarinese',
  'sao tomean',
  'saudi arabian',
  'scottish',
  'senegalese',
  'serbian',
  'seychellois',
  'sierra leonean',
  'singaporean',
  'slovenian',
  'solomon islander',
  'somali',
  'south african',
  'spanish',
  'sri lankan',
  'sudanese',
  'surinamese',
  'swazi',
  'swedish',
  'swiss',
  'syrian',
  'taiwan',
  'tajikistani',
  'tanzanian',
  'thai',
  'togolese',
  'tokelauan',
  'tongan',
  'trinidadian',
  'tobagonian',
  'tunisian',
  'turkish',
  'turkmen',
  'tuvaluan',
  'ugandan',
  'ukrainian',
  'emirati',
  'british',
  'american',
  'uruguayan',
  'uzbekistani',
  'ni-vanuatu',
  'vantican',
  'venezuelan',
  'vietnamese',
  'virgin islander',
  'yemeni',
  'yugoslavian',
  'zambian',
  'zimbabwean',
];

module.exports = [...new Set(nationalities.map(n => capitalize(n)))];
