import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Button, Select } from 'antd';

import CustomRow from 'shared/components/CustomRow';

import { ARTIST_STATUS } from 'shared/constants/app';

export class Search extends React.PureComponent {
  static propTypes = {
    form: PropTypes.shape({
      getFieldDecorator: PropTypes.func.isRequired,
      getFieldsValue: PropTypes.func.isRequired,
      resetFields: PropTypes.func.isRequired,
    }).isRequired,

    searchArtists: PropTypes.func.isRequired,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        placeholder: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
      }),
    ).isRequired,
    statuses: PropTypes.arrayOf(PropTypes.string).isRequired,

    setFilterLinkArtists: PropTypes.func.isRequired,
  };

  componentDidMount() {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    const id = params.get('id');
    const name = params.get('name');
    const birth = params.get('birth');

    this.props.form.setFieldsValue({ id, name, birth });
    this.onSearch();
  }

  onValueChange(fieldName, fieldValue) {
    this.setFilterParamLink({ [fieldName]: fieldValue });
  }

  setFilterParamLink(params) {
    const { id, name, birth } = params;

    const values = this.props.form.getFieldsValue();

    this.props.setFilterLinkArtists({
      id: id !== undefined ? id : values.id,
      name: name !== undefined ? name : values.name,
      birth: birth !== undefined ? birth : values.birth,
    });
  }

  onSearch = () => {
    const { search } = window.location;
    const params = new URLSearchParams(search);

    const idQueryParams = params.get('id');
    const nameQueryParams = params.get('name');
    const birthQueryParams = params.get('birth');

    const values = this.props.form.getFieldsValue();

    values.id = values.id || idQueryParams;
    values.name = values.name || nameQueryParams;
    values.birth = values.birth || birthQueryParams;

    this.props.searchArtists(values);
  };

  onClear = () => {
    this.props.form.resetFields();
    this.props.searchArtists();
  };

  render() {
    const {
      form: { getFieldDecorator, isFieldsTouched },
      fields,
    } = this.props;

    const isButtonsDisabled = !isFieldsTouched();

    return (
      <React.Fragment>
        <CustomRow>
          <Form layout="inline">
            {fields.map(field => (
              <Form.Item key={field.placeholder} label={field.placeholder}>
                {getFieldDecorator(field.name)(
                  <Input
                    type={field.type}
                    placeholder={field.placeholder}
                    onChange={e => this.onValueChange(field.name, e.target.value)}
                  />,
                )}
              </Form.Item>
            ))}
            <Form.Item label="Status">
              {getFieldDecorator('artist_status', {
                initialValue: null,
              })(
                <Select style={{ width: 150 }}>
                  <Select.Option value={null}>all</Select.Option>
                  {this.props.statuses.map(status => (
                    <Select.Option key={status} value={status}>
                      {status}
                    </Select.Option>
                  ))}
                  <Select.Option key="hidden" value="hidden">
                    hidden
                  </Select.Option>
                  <Select.Option key="high_priority" value="high_priority">
                    high priority
                  </Select.Option>
                </Select>,
              )}
            </Form.Item>
          </Form>
        </CustomRow>

        <div>
          <Button.Group>
            <Button onClick={this.onClear} disabled={isButtonsDisabled}>
              Clear Fields
            </Button>
            <Button type="primary" onClick={this.onSearch} disabled={isButtonsDisabled}>
              Find
            </Button>
          </Button.Group>
        </div>
      </React.Fragment>
    );
  }
}
