import { handleActions, combineActions } from 'redux-actions';
import produce from 'immer';
import {
  getArtistRequest,
  getArtistSuccess,
  getArtistError,
  setPage,
  setSortDirection,
  setSortBy,
  setFilters,
  setArtistToEdit,
  updateArtistRequest,
  openCreateArtistModal,
  closeCreateArtistModal,
  openMergeArtistModal,
  closeMergeArtistModal,
  setToInitialState,
  createArtistRequest,
  createArtistSuccess,
  createArtistError,
} from 'artist/actions/artist-page';

import { errorProducer, setFiltersProducer, setPageProducer } from 'shared/helpers/producers';

import { ITEMS_PER_PAGE } from 'shared/constants/items-per-page';
import { ARTIST_STATUS } from 'shared/constants/app';

const initState = {
  isLoading: false,
  success: false,
  count: 0,
  pagesCount: 0,
  ids: [],
  items: {},
  filters: { artist_status: ARTIST_STATUS.VERIFIED },
  offset: 0,
  currentPage: 1,
  // sortDir: 'asc',
  // sortBy: 'id',
  artistToEdit: null,
  isCreateArtistModalOpen: false,
  isMergeArtistModalOpen: false,
  isArtistCreating: false,
};

export const artistReducer = handleActions(
  {
    [getArtistRequest]: produce(draft => {
      draft.isLoading = true;
    }),

    [getArtistSuccess]: produce((draft, action) => {
      if (action.payload.itemToEditId) {
        draft.artistToEdit = action.payload.items[action.payload.itemToEditId];
      }
      draft.ids = action.payload.ids;
      draft.items = action.payload.items;
      draft.count = action.payload.count;
      draft.pagesCount = Math.ceil(action.payload.count / ITEMS_PER_PAGE);
      draft.success = true;
      draft.isLoading = false;
    }),
    [getArtistError]: produce(errorProducer),
    [setPage]: produce(setPageProducer),
    [setSortDirection]: produce((draft, action) => {
      draft.sortDir = action.payload.sortDir;
    }),
    [setSortBy]: produce((draft, action) => {
      draft.sortBy = action.payload.sortBy;
    }),
    [setFilters]: produce(setFiltersProducer),
    [setArtistToEdit]: produce((draft, action) => {
      draft.artistToEdit = action.payload.artistToEdit;
    }),
    [updateArtistRequest]: produce(draft => {
      draft.isLoading = true;
    }),
    [openCreateArtistModal]: produce(draft => {
      draft.isCreateArtistModalOpen = true;
    }),
    [closeCreateArtistModal]: produce(draft => {
      draft.isCreateArtistModalOpen = false;
    }),
    [openMergeArtistModal]: produce(draft => {
      draft.isMergeArtistModalOpen = true;
    }),
    [closeMergeArtistModal]: produce(draft => {
      draft.isMergeArtistModalOpen = false;
    }),
    [setToInitialState]: produce(draft => {
      Object.keys(initState).forEach(key => {
        draft[key] = initState[key];
      });
    }),
    [createArtistRequest]: produce(draft => {
      draft.isArtistCreating = true;
    }),

    [combineActions(createArtistSuccess, createArtistError)]: produce(draft => {
      draft.isArtistCreating = false;
    }),
  },
  initState,
);
